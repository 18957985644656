import React, { PureComponent } from 'react'

import { stripNonDigits } from './constants'
import InputWithLabel from './input-with-label'

import { bool, func, string } from 'prop-types'


export default class VerificationCodeInput extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      (value) => {
        if (!props.required) return null
        if (!value) return 'A code is required.'
      },
      (value) => {
        if (value.length !== 4) return 'A 4 digit code is required.'
      }
    ]
  }

  static contextTypes = {
    onChange: func
  }

  static propTypes = {
    onChange: func,
    name: string,
    required: bool
  }

  static defaultProps = {
    onChange: () => {}
  }

  handleChange = (e) => {
    this.props.onChange(e) // used to notify parent of changes
    this.context.onChange(this.props.name, this.formatter(e.target.value), this.wrapper.inputContainer.isValid())
  }

  parser = (value) => {
    // Leave digits only
    return value ? stripNonDigits(value) : ''
  }

  formatter = (value) => value

  render() {
    // Use tel type input to force number keyboard on mobile devices
    return (
      <InputWithLabel
        {...this.props}
        placeholder="Enter your code"
        name={this.props.name}
        parser={this.parser}
        type="tel"
        maxLength="4"
        formatter={this.formatter}
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
      />
    )
  }
}
