/**
 * Component to add float labels on top of Input,
 * consumed by higher level inputs components like ./email, ./password, etc.
 */

import React from 'react'

import styled from '@emotion/styled'

import Input, { AUTOFILL_ANIMATION_START, AUTOFILL_ANIMATION_CANCEL } from './input'
import { Label, FloatLabel } from './label'

import { string, func } from 'prop-types'

const Container = styled.div`
  position: relative;
  width: 100%;
`

export default class InputWithLabel extends React.Component {
  static propTypes = {
    name: string.isRequired,
    placeholder: string.isRequired,
    initialValue: string,
    onChange: func,
    id: string
  }

  static defaultProps = {
    onChange: () => {}
  }

  state = {
    hasValue: Boolean(this.props.initialValue)
  }

  componentDidMount() {
    this.inputContainer.input &&
      this.inputContainer.input.addEventListener &&
      this.inputContainer.input.addEventListener('animationstart', this.handleAnimations)
  }

  componentWillUnmount() {
    this.input &&
      this.inputContainer.input.removeEventListener &&
      this.inputContainer.input.removeEventListener('animationstart', this.handleAnimations)
  }

  handleAnimations = (e) => {
    // This is to handle autofill issues for iOS / OSX devices
    // courtesy of https://stackoverflow.com/a/41530164, for future code spelunkers
    switch (e.animationName) {
      // Handle autofill with this hook
      case AUTOFILL_ANIMATION_START:
        return this.setState({ hasValue: true })

      case AUTOFILL_ANIMATION_CANCEL:
        if (e.target.value && e.target.value.length > 0) return null
        return this.setState({ hasValue: false })

      default:
        return null
    }
  }

  handleChange = (e) => {
    if (e.target.value && e.target.value.length > 0) {
      this.setState({ hasValue: true })
    } else {
      this.setState({ hasValue: false })
    }

    this.props.onChange(e)
  }

  handleLabelClick = (e) => {
    e.preventDefault()
    this.inputContainer.input.focus()
  }

  render() {
    const { placeholder, ...remainingProps } = this.props
    const labelProps = {
      htmlFor: this.props.id || this.props.name,
      float: this.state.hasValue
    }

    return (
      <Container>
        {this.state.hasValue ? (
          <FloatLabel {...labelProps} onClick={this.handleLabelClick}>
            {placeholder}
          </FloatLabel>
        ) : (
          <Label {...labelProps} onClick={this.handleLabelClick}>
            {placeholder}
          </Label>
        )}
        <Input
          ref={(inputContainer) => {
            this.inputContainer = inputContainer
          }}
          placeholder={placeholder}
          {...remainingProps}
          hasValue={this.state.hasValue}
          value={this.props.initialValue}
          onChange={this.handleChange}
        />
      </Container>
    )
  }
}
