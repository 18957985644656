import React from 'react'

export default function Eye() {
  return (
    <svg width="24px" height="14px" viewBox="0 0 24 14" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        transform="translate(-278.000000, -180.000000) translate(278.000000, 180.000000)"
        stroke="#000"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M23.5 7s-5.148 6.5-11.5 6.5S.5 7 .5 7 5.648.5 12 .5 23.5 7 23.5 7z" />
        <path d="M16 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
        <path d="M12 5a2 2 0 1 1-2 2" />
      </g>
    </svg>
  )
}
