/**
 * Select dropdown input component
 */

import React, { PureComponent } from 'react'

import styled from '@emotion/styled'

import colors from 'microcomponents/colors'

import { func, string, node, object } from 'prop-types'

// import ARROW from './arrow.png'

const ARROW =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAABmJLR0QA/wD/AP+gvaeTAAABL0lEQVQokaXPvUoDQRSG4XdmEtOKTe5AIsYbEBQi2NmksPUKJI1VNu5yduOIhZ1oa2EhWFpa+FNbKIIIIhZ22mhjIcnO2CRgYuIaPd1w5nv4DjawRRvYIv+cJExmAHRqWntt07oWkdJfsTiSbY+7aa43p7Qz1AGnUi6SMJkevZlEeNZQ7IYb4Z0WkXtvqABtjzsdBU1CWfUQ4znwmhqA6i5FZFKlnAE5hV6ImtFtBrbiYV/BsTMsi0i7BxwFjaO4ivdHCs7H3yaWaju1j+5O94GZ58dRvIj3h8ClM1S/Yt8aZjWVhswqzQnwMNYqVOpb9df+7EBwEOqMy3feL7k0P9/YbDwPyg0Fe1FVAA/w7g1zIvI0LPMj2EFLOuXKA94wLSKPWZnMsYEt28CWf/P3E0tFioMM9ktHAAAAAElFTkSuQmCC'

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 0.4rem;
  border: 1px solid ${colors.accessory[4]};

  & > select {
    width: 100%;
  }
`

const StyledSelect = styled.select`
  border: 0;
  width: 100%;
  height: 4rem;
  cursor: pointer;
  font-size: 1.4rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  background-color: white;
  -webkit-appearance: none;
  color: ${colors.accessory[2]};
  background-image: url('${ARROW}');
  background-position: calc(100% - 1.5rem);
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`

export default class Select extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    value: string,
    children: node,
    componentStyle: object,
    e2eid: string
  }

  render() {
    const { children, componentStyle, onChange, value, e2eid } = this.props

    return (
      <Container>
        <StyledSelect style={{ ...componentStyle }} onChange={onChange} value={value} data-e2eid={e2eid}>
          {children}
        </StyledSelect>
      </Container>
    )
  }
}
