/**
 * Checkbox input component
 */

import React, { PureComponent } from 'react'

import styled from '@emotion/styled'

import colors from 'microcomponents/colors'

import Input from './input'

import { array, bool, func, object, oneOfType, string } from 'prop-types'

const Label = styled.label`
  padding: 0.6rem;
  box-sizing: border-box;
  margin: 0.5rem 0 1rem 0;
  display: block;
  font-size: 1rem;
  display: flex;
  background: ${fetchLabelBackground};
  border-radius: 4px;

  & a,
  & a:link,
  & a:visited,
  & a:hover {
    color: ${colors.indica[1]};
  }
`

const CheckboxText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${fetchTextColor};
`

function fetchLabelBackground(props) {
  if (props.error) {
    return colors.danger[5]
  } else if (props.type === 'AGREEMENT') {
    return 'rgba(92, 81, 90, 0.2)'
  } else {
    return null
  }
}

function fetchTextColor(props) {
  if (props.error) {
    return colors.danger[1]
  }

  return 'white'
}

const Checkbox = styled(Input)`
  width: inherit;
  height: 1.2rem;
  margin: 0 1rem 0 0.25rem;
`

export default class CheckboxComponent extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      (value) => {
        if (!props.required) return null
        if (!value) return 'This must be checked.'
      }
    ]
  }

  static contextTypes = {
    onChange: func,
    register: func,
    deregister: func
  }

  static propTypes = {
    children: oneOfType([array, object]),
    name: string,
    required: bool,
    type: string
  }

  state = {
    touched: false
  }

  componentDidMount() {
    // name, then initial value, then false to indicate this starts as not valid if this is required
    this.context.register(this.props.name, this.input.checked, !this.props.required)
  }

  componentWillUnmount() {
    this.context.deregister(this.props.name)
  }

  handleChange = (e) => {
    this.setState({ touched: true })
    this.context.onChange(this.props.name, e.target.checked, this.input.isValid())
  }

  render() {
    const { children, name, required, type } = this.props
    const hasError = this.state.touched && !this.input.isValid()
    return (
      <Label type={type} error={hasError}>
        <Checkbox
          type="checkbox"
          name={name}
          checked={this.state.value}
          onChange={this.handleChange}
          validators={this.validators}
          ref={(input) => {
            this.input = input
          }}
          required={required}
        />
        <CheckboxText error={hasError}>
          <div>{children}</div>
        </CheckboxText>
      </Label>
    )
  }
}
