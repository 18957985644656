/**
 * Component that allows for hiding/showing of password inputs.
 *
 * This component will be a bit of a mega component that combines functionality
 * from higher-order components like ./email and logic from ./input-with-label.
 *
 * 1) It needs to maintain its own state on top of the Input state
 * so that it can switch between two types of inputs
 * 2) It needs to register and change state like a ./password component
 * 3) It needs to reimplement logic from ./input-with-label because it needs
 * to retain the label between the two different inputs.
 * 4) It needs to submit as a password
 */

import React, { PureComponent } from 'react'

import styled from '@emotion/styled'

import colors from 'microcomponents/colors'

import EyeClosed from './eye-closed.js'
import Eye from './eye.js'
import validators from './validators'

import { string, func } from 'prop-types'

import Input from '../input'
import { Label, FloatLabel } from '../label'


export default class ShowPassword extends PureComponent {
  static propTypes = {
    // ** Required propTypes ** //
    // Other props not listed here will be checked by Input
    // Name of input. Required for submitting values correctlyw
    name: string.isRequired,
    // Placeholder to use in label
    placeholder: string.isRequired,
    initialValue: string,
    id: string
  }

  static contextTypes = {
    onChange: func,
    register: func
  }

  state = {
    value: this.props.initialValue || '',
    showPassword: false
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, e.target.value, this.wrapper.isValid())
    this.setState({ value: e.target.value })
  }

  componentDidMount() {
    this.context.register(this.props.name)
  }

  handleShowingPassword = () => {
    // When a user clicks/taps the eye in mobile, we don't want to lose the keyboard,
    // so automatically focus on the input
    this.wrapper.input.focus()
    this.setState({ showPassword: !this.state.showPassword })
  }

  renderEyecon() {
    return (
      <EyeComponent onClick={this.handleShowingPassword}>
        {this.state.showPassword ? <EyeClosed /> : <Eye />}
      </EyeComponent>
    )
  }

  render() {
    const { showPassword } = this.state

    const { placeholder, ...remainingProps } = this.props

    const hasValue = this.wrapper && this.wrapper.value()

    const labelProps = {
      htmlFor: this.props.id || this.props.name,
      float: hasValue
    }

    return (
      <Container>
        {hasValue ? (
          <FloatLabel {...labelProps}>{placeholder}</FloatLabel>
        ) : (
          <Label {...labelProps}>{placeholder}</Label>
        )}
        <Input
          className="fs-exclude"
          {...remainingProps}
          type={showPassword ? 'text' : 'password'}
          onChange={this.handleChange}
          validators={validators(this)}
          inputIcon={this.renderEyecon()}
          ref={(wrapper) => {
            this.wrapper = wrapper
          }}
        />
      </Container>
    )
  }
}

const EyeComponent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.primary[5]};
  border: 1px solid ${colors.accessory[4]};
  border-radius: 0.4rem;
  margin: 0.5rem 0;
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
`

const Container = styled.div`
  position: relative;
`
