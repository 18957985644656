/**
 * Text field input
 */
import React, { PureComponent } from 'react'

import InputWithLabel from './input-with-label'

import { func, string, bool } from 'prop-types'


export default class TextField extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      (value) => {
        if (!props.required) return null
        const name = props.placeholder || 'Value'
        // backend validation requires 2 char minimum
        if (!value || value.trim().length < 2) return `${name} is required.`
      }
    ]
  }

  static contextTypes = {
    onChange: func
  }

  static propTypes = {
    name: string,
    placeholder: string,
    required: bool
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, e.target.value, this.wrapper.inputContainer.isValid())
  }

  render() {
    return (
      <InputWithLabel
        {...this.props}
        name={this.props.name}
        type="text"
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
      />
    )
  }
}
