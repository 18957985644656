/**
 * This is used to get around issues created by using React context methods.
 * Based on https://medium.com/@mweststrate/how-to-safely-use-react-context-b7e343eff076#.b3r9anw1i
 */

export default class FormSubscriber {
  constructor() {
    this.inputs = {}
  }

  updateInputs() {
    const keys = Object.keys(this.inputs)

    for (let i = 0; i < keys.length; i++) {
      this.inputs[keys[i]]()
    }
  }

  subscribe(key, fn) {
    this.inputs[key] = fn
  }

  unsubscribe(key) {
    delete this.inputs[key]
  }
}
