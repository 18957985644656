/**
 * Phone number input
 * It doesn't handle international prefix
 */

import React, { PureComponent } from 'react'

import isPhone from 'is-phone'

import { formatter, parser } from './constants'
import InputWithLabel from './input-with-label'

import { func, string, bool } from 'prop-types'

export default class PhoneNumberInput extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      (value) => {
        if (!props.required) return null
        if (!value) return 'Phone number is required.'
      },
      (value) => {
        if (!value) return null
        return isPhone(String(value)) ? null : 'Invalid phone number.'
      }
    ]
  }

  static contextTypes = {
    onChange: func
  }

  static propTypes = {
    initialValue: string,
    name: string,
    required: bool
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, formatter(e.target.value), this.wrapper.inputContainer.isValid())
  }

  render() {
    // Use tel type input to force number keyboard on mobile devices
    return (
      <InputWithLabel
        // fs-exlude to prevent PII data from being captured by fullstory
        className="fs-exlude"
        {...this.props}
        placeholder="Phone Number"
        name={this.props.name}
        parser={parser}
        formatter={formatter}
        type="tel"
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
        initialValue={this.props.initialValue}
      />
    )
  }
}
