import React from 'react'

export default function EyeClosed() {
  return (
    <svg width="25px" height="20px" viewBox="0 0 25 20" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        transform="translate(-277.000000, -177.000000) translate(278.000000, 177.000000)"
        stroke="#000"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M19.518 6.288C21.957 8.052 23.5 10 23.5 10s-5.148 6.5-11.5 6.5c-1.04 0-2.045-.173-3.002-.465m-4.53-2.334C2.037 11.941.5 10.001.5 10.001S5.649 3.5 12 3.5c.86 0 1.698.118 2.504.324M8 10a4 4 0 0 1 4-4m4 4a4 4 0 0 1-4 4M21.75.25l-19.5 19.5" />
      </g>
    </svg>
  )
}
