/**
 * Input component, consumed by higher level inputs components like ./input-with-label
 *
 * This component packages 2 pieces together - the input itself, and validation errors that
 * come with it.
 */

import React, { PureComponent } from 'react'

import styled from '@emotion/styled'
import omit from 'object.omit'

import colors from 'microcomponents/colors'

import { CHECKBOX } from './constants'

import { func, string, bool, arrayOf, object } from 'prop-types'

export const AUTOFILL_ANIMATION_START = 'onAutoFillStart'
export const AUTOFILL_ANIMATION_CANCEL = 'onAutoFillCancel'

export default class InputComponent extends PureComponent {
  static propTypes = {
    // ** Required propTypes ** //
    // Formatter takes the value and reformats it
    formatter: func.isRequired,
    // Name of input. Required for submitting values correctly
    name: string.isRequired,
    // Placeholder for input
    placeholder: string,
    // Parser takes the value and gets the data ready for form submission
    parser: func.isRequired,
    type: string.isRequired,

    // ** Optional propTypes ** //
    // specifying a className will OVERWRITE the default input types, useful for things
    // types with drastically different styles, like stylized checkboxes
    className: string,
    // Eager validating inputs will show errors onBlur instead of on form submit
    eagerValidate: bool,
    // Whether it should be styled as if it had a value. Primarily used for styling on autofill.
    hasValue: bool,
    // Some cases require us to show any input an error has already. An example
    // is with the password input that allows the user to reveal what's been typed
    initialErrorState: bool,
    // Will be run through the formatter to get initial display value
    initialValue: string,
    // Passed onBlur handlers will be fired at the start of the onBlur function defined here
    onBlur: func,
    // Passed onChange handlers will be fired at the start of the onChange function defined here
    onChange: func,
    validators: arrayOf(func).isRequired,
    cursorFix: bool,
    errorColor: string,
    id: string,
    inputIcon: object
  }

  static defaultProps = {
    type: 'text',
    eagerValidate: false,
    formatter: (value) => value,
    initialErrorState: false,
    initialValue: '',
    onBlur: () => {},
    onChange: () => {},
    parser: (value) => {
      return value
    },
    // Assume blank inputs have no validator functions
    validators: [],
    errorColor: colors.danger[2]
  }

  constructor(props) {
    super(props)

    const {
      formatter,
      name,
      parser,
      type,
      eagerValidate,
      initialErrorState,
      initialValue,
      validators,
      inputIcon,
      ...inputProps
    } = props

    // use inputProps as a catch-all for other props that can go on default `input` html components,
    // like placeholder, autocomplete, etc.
    this.inputProps = inputProps

    this.name = name
    this.parser = parser
    this.type = type
    this.eagerValidate = eagerValidate
    this.initialValue = initialValue
    this.validators = validators
    this.inputIcon = inputIcon

    this.state = {
      // refers to whether the input should show errors.
      // For eager-validation inputs, this will flip onBlur.
      // For other inputs, this should be flipped on submit of the parent form.
      showErrors: initialErrorState,
      errors: [],
      value: formatter(this.props.initialValue)
    }
  }

  static contextTypes = {
    onChange: func,
    register: func,
    deregister: func
  }

  componentDidMount() {
    this.input && this.input.addEventListener('change', this.onChange)

    this.context.register(this.name, this.initialValue)

    if (this.initialValue) {
      // borrow onChange context method to trigger initalValue validation
      this.context.onChange(this.name, this.initialValue, this.isValid())
    }
  }

  componentWillUnmount() {
    this.input && this.input.removeEventListener('change', this.onChange)

    this.context.deregister(this.name)
  }

  /**
   * Runs each validator function against the parsed value and adds resulting strings to array.
   * This is run on every change of the input, though errors are only displayed when state.showErrors is true
   * @return {[String]} Array of strings describing validation errors
   */
  getErrors = (value) => {
    const messages = []

    for (let i = 0; i < this.props.validators.length; i++) {
      const validator = this.props.validators[i]
      const message = validator(this.props.parser(value))
      if (message) {
        messages.push(message)
      }
    }

    return messages
  }

  /**
   * Checks if the input is valid by running the validators.
   * Valid inputs will have no validation messages
   * @return {Boolean} whether the input is valid or not
   */
  isValid = () => {
    return this.getErrors(this.parsedValue()).length === 0
  }

  /**
   * Used to set if the input has been blurred.
   */
  handleBlur = (e) => {
    this.props.onBlur(e)

    // Show any validation errors if eagerValidate is set to true
    if (this.props.eagerValidate) {
      this.setState({
        showErrors: true,
        errors: this.getErrors(this.parsedValue())
      })
    }
  }

  /**
   * handleChange updates the value and constantly runs validators
   */
  handleChange = (e) => {
    /*
      Fixes android cursor position going out of place when formatting input value
      I tried:
      - using a different formatter.
      - updating it to our new form system.
      - setSelectionRange w/o the setTimeout
      - Queueing an additional setState

      I beg forgivess for this transgression about to occur.
    */
    const fValue = this.formattedValue()
    const cursorFix = this.props.cursorFix
      ? () => {
          setTimeout(() => {
            this.input.setSelectionRange(fValue.length, fValue.length)
          }, 0)
        }
      : () => {}

    this.setState(
      {
        value: this.formattedValue(),
        errors: this.getErrors(this.parsedValue())
      },
      cursorFix // setState callback
    )

    // Reset error visibility if all parts of the input are deleted
    if (e.target.value.length === 0) {
      this.setState({
        showErrors: false
      })
    }

    this.props.onChange(e)
  }

  /**
   * Return value of input, which is used by #formattedValue and #parsedValue
   * Also used to check for presence of a value for `./input-with-label`
   * @return {String}
   */
  value() {
    if (!this.input) return ''

    switch (this.props.type) {
      case CHECKBOX:
        return this.input.checked
      default:
        return this.input.value
    }
  }

  /**
   * Formatted values should be displayed to the user.
   */
  formattedValue() {
    // always format the raw value of the input
    return this.props.formatter(this.parsedValue())
  }

  /**
   * Parsed values should be used for validation and for form submission
   */
  parsedValue() {
    return this.props.parser(this.value())
  }

  /**
   * Returns jsx for display of errors, when state.showErrors is true, used in #render
   */
  errorList = () => {
    return this.state.errors.map((errorMessage, index) => {
      return <ErrorMessage key={index}>{errorMessage}</ErrorMessage>
    })
  }

  render() {
    const shouldShowErrors = !this.isValid() && this.state.showErrors
    // Filter invalid input attributes
    const inputProps = omit(this.inputProps, 'cursorFix')

    return (
      <Container>
        <Container>
          <Input
            {...inputProps}
            value={this.state.value}
            id={this.props.id || this.props.name}
            type={this.props.type}
            name={this.props.name}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onInput={this.handleChange}
            hasValue={this.props.hasValue || (this.props.name && Boolean(this.state.value))}
            ref={(input) => {
              this.input = input
            }}
            shouldShowErrors={shouldShowErrors}
          />
          {this.props.inputIcon ? this.props.inputIcon : null}
        </Container>
        {shouldShowErrors && <Errors errorColor={this.props.errorColor}>{this.errorList()}</Errors>}
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
`

const ErrorMessage = styled.li`
  padding: 0;
`

const Input = styled.input`
  color: ${colors.accessory[1]};
  padding: ${({ hasValue }) => (hasValue ? '2.25rem 1rem 0.5rem' : '1rem')};
  border: 1px solid ${fetchInputBorder};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  cursor: pointer;
  height: ${({ type }) => (type === 'checkbox' ? 'inherit' : '4.5rem')};
  box-sizing: border-box;
  font-weight: normal;
  margin: 0.5rem 0;
  width: 100%;
  z-index: 0;
  background-color: ${({ shouldShowErrors }) => (shouldShowErrors ? colors.danger[5] : null)};

  &::placeholder {
    color: transparent;
    font-size: 1.6rem;
  }

  &::-webkit-input-placeholder {
    color: transparent;
    font-size: 1.6rem;
  }

  &::-moz-placeholder {
    color: transparent;
    font-size: 1.6rem;
  }

  &::-ms-input-placeholder {
    color: transparent;
    font-size: 1.6rem;
  }

  &.errorInput::placeholder {
    color: ${colors.danger[5]};
  }

  &:invalid {
    box-shadow: none;
  }

  @media (max-width: 767px) {
    & textarea,
    & input[type='text'],
    & input[type='date'],
    & input[type='tel'],
    & input[type='email'],
    & input[type='password'] {
      -webkit-appearance: none;
    }
  }
`

function fetchInputBorder({ shouldShowErrors }) {
  return shouldShowErrors ? colors.danger[1] : colors.accessory[4]
}

const Errors = styled.ul`
  color: ${({ errorColor }) => errorColor};
  font-size: 1.2rem;
  list-style: none;
  padding: 0;
  margin: 0;
`
