/**
 * Have single validator here to reference in ./index and in ../show-password
 */

const MINIMUM_LENGTH = 6
const validator = (context) => {
  return [
    (value) => {
      if (!context.props.required) return null
      if (!value) return 'Password is required.'
    },
    (value) => {
      if (!value) return null
      return value.length >= MINIMUM_LENGTH
        ? null
        : `Please enter a password with at least ${MINIMUM_LENGTH} characters`
    }
  ]
}

export default validator
