/*
 * Zipcode input
 */
import React, { PureComponent } from 'react'

import InputWithLabel from './input-with-label'

import { func, string, bool } from 'prop-types'


const nonDigit = /[^\d]/g
const parseZip = (zip) => zip.replace(nonDigit, '').substring(0, 5)
const validateZip = (zip) => zip.length === 5 && !nonDigit.test(zip)

export default class Zipcode extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      (value) => {
        if (!props.required) return null
        if (!value) return 'Zipcode is required.'
      },
      (value) => {
        if (!value) return null
        return validateZip(value) ? null : 'Please enter a valid zipcode'
      }
    ]
  }

  static contextTypes = {
    onChange: func,
    register: func,
    deregister: func
  }

  static propTypes = {
    initialValue: string,
    name: string,
    required: bool
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, this.parser(e.target.value), this.wrapper.inputContainer.isValid())
  }

  componentDidMount() {
    this.context.register(this.props.name, this.props.initialValue)
  }

  componentWillUnmount() {
    this.context.deregister(this.props.name)
  }

  parser(value) {
    return parseZip(value || '')
  }

  render() {
    // Use number type input to force number keyboard on mobile devices
    return (
      <InputWithLabel
        {...this.props}
        name={this.props.name}
        type="number"
        parser={this.parser}
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
      />
    )
  }
}
