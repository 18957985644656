/**
 * Email input
 * The validation is based on https://github.com/sindresorhus/email-regex
 */

import React, { PureComponent } from 'react'

import emailRegex from 'email-regex'

import InputWithLabel from './input-with-label'

import { func, bool, string } from 'prop-types'

export default class Email extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      // check for null if required
      (value) => {
        if (!props.required) return null
        if (!value) return 'Email is required'
      },
      // check for valid email entered
      (value) => {
        if (!value) return null
        const match = emailRegex({ exact: true }).test(value)
        return match ? null : 'Please enter a valid email'
      }
    ]
  }

  static contextTypes = {
    onChange: func
  }

  static propTypes = {
    id: string,
    name: string,
    required: bool
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, e.target.value, this.wrapper.inputContainer.isValid())
  }

  render() {
    return (
      <InputWithLabel
        {...this.props}
        name={this.props.id || this.props.name}
        type="email"
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
      />
    )
  }
}
